<template>
  <div class="lesson-view">
    <div class="wrapper">
      <h6 class="name">
        {{ lesson.title }}
      </h6>
      <div class="bottom-line d-flex align-items-center">
        <small class="flex-grow-1 mr-1">
          {{ formatChatDate(lesson.lastMessageDate) }}
        </small>
        <small class="stream-name flex-grow-1">
          {{ lesson.streamTitle }}
        </small>
        <span
          class="d-flex align-items-center mx-50"
          :class="{
            'not-viewed': lesson.viewed === false,
          }"
        >
          {{ lesson.messagesCount }}
          <feather-icon size="15" icon="MessageSquareIcon" class="ml-25" />
        </span>
        <!-- <span> -->
        <feather-icon size="15" :icon="icon" :class="statusClass" />
        <!-- </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { formatChatDate } from '@/helpers/dateFormatter'

export default {
  components: {},
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      if (this.lesson.isRepeated) return 'RepeatIcon'
      else if (this.lesson.isApprove) return 'CheckIcon'
      else return 'SlashIcon'
    },
    statusClass() {
      if (this.lesson.isRepeated) return 'text-primary'
      else if (this.lesson.isJustCreated) return 'opacity-0'
      else if (this.lesson.isApprove) return 'text-success'
      else return 'text-danger'
    },
  },
  methods: {
    formatChatDate,
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.lesson-view {
  cursor: pointer;

  .bottom-line {
    white-space: nowrap;
    overflow: hidden;

    .stream-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .opacity-0 {
    opacity: 0;
  }

  .not-viewed {
    color: $primary;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.selected {
    background: linear-gradient(to right, $primary, rgba($primary, 0.8));

    .not-viewed {
      color: $warning;
    }

    .name {
      color: $white;
    }

    .bottom-line {
      color: $theme-dark-label-color;
    }
  }

  .wrapper {
    padding: 10px 20px;
  }
}
</style>