<template>
  <div class="student-view position-relative">
    <div v-if="student.refund" class="refund-overlay" />
    <div class="wrapper d-flex align-items-center">
      <b-avatar size="3rem" :src="student.avatarUrl" />
      <div class="collapsed-count d-flex justify-content-center">
        <b-badge v-if="student.messagesCount > 0" pill variant="primary">
          {{ student.messagesCount }}
        </b-badge>
      </div>
      <div class="flex-grow-1 text-block mx-1">
        <h6 class="name">{{ student.name }}</h6>
        <small class="message">
          {{ extractContent(student.lastMessage) }}
        </small>
      </div>
      <div class="d-flex flex-column">
        <small class="date">{{ messageDate }}</small>
        <b-badge v-if="student.messagesCount > 0" pill variant="primary">
          {{ student.messagesCount }}
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'

const months = [
  'Янв',
  'Фев',
  'Март',
  'Апр',
  'Май',
  'Июнь',
  'Июль',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек',
]

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messageDate() {
      const date = new Date(this.student.lastMessageDate)

      let day = date.getDate()
      if (day < 10) day = '0' + day

      return `${day} ${months[date.getMonth()]}`
    },
  },
  methods: {
    getMonthString(month) {
      return months[parseInt(month) - 1]
    },
    extractContent(message) {
      const span = document.createElement('span')
      span.innerHTML = message
      return span.textContent || span.innerText
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.collapsed {
  .text-block {
    opacity: 0 !important;
  }

  .collapsed-count {
    opacity: 1 !important;
  }
}

.student-view {
  width: 100%;
  cursor: pointer;
  position: relative;

  .refund-overlay {
    position: absolute;
    opacity: 0.1;
    background-color: red;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .collapsed-count {
    transition: 0.5s opacity ease-in-out;
    position: absolute;
    opacity: 0;
    top: 40px;
    left: 35px;
    width: 40px;
  }

  .wrapper {
    padding: 7px 15px;

    .date {
      white-space: nowrap;
    }

    .text-block {
      transition: 0.5s opacity ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;

      .name {
        margin: 0;
        font-weight: bold;
      }

      .message {
        white-space: nowrap;
      }
    }
  }

  &.selected {
    background: linear-gradient(to right, $primary, rgba($primary, 0.8));

    .message {
      color: $theme-dark-label-color !important;
    }

    .date,
    .name {
      color: $white !important;
    }
  }
}
</style>
