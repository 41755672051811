<template>
  <td class="sub-cell" :class="backgroundClass">
    <div
      v-if="homework"
      class="lesson-info position-relative"
      :class="{ homework: homework.homework }"
      @click="lessonClick"
    >
      <div
        v-if="homework.homework && homework.homework.unreadMessagesCount > 0"
        class="message-indicator d-flex align-items-center"
      >
        {{ homework.homework.unreadMessagesCount }}
        <feather-icon size="12" icon="MessageSquareIcon" />
      </div>

      {{ homework.date }}
    </div>
  </td>
</template>

<script>
export default {
  props: {
    lesson: { type: Object, required: true },
    homework: { type: Object },
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundClass() {
      if (this.homework === undefined)
        return this.lesson.date_start > new Date() ? 'not-available' : ''

      switch (this.homework.status) {
        case 'none':
          return 'new'
        case 'repeated':
          return 'repeated'
        case 'approved':
          if (this.homework.homework || this.homework.justPassed)
            return 'approved'
          else
            return this.homework.test.result === 'good' ? 'approved' : 'test-ok'
        case 'rejected':
          return 'rejected'
      }
    },
  },
  methods: {
    async lessonClick() {
      if (!this.homework.homework) return
      this.$store.commit('homeworkReviewStore/setMentorsFilter', [])
      this.$store.commit('homeworkReviewStore/setLessonsFilter', [])
      await this.$store.dispatch('homeworkReviewStore/fetchStudents')
      await this.$store.dispatch(
        'homeworkReviewStore/fetchDetailedStudentData',
        this.student.id,
      )
      await this.$store.dispatch(
        'homeworkReviewStore/fetchDialog',
        this.homework.homework.dialogId,
      )
      this.$store.dispatch('homeworkReviewStore/updateRouteQuery')
      this.$bvModal.hide('homeworks-table-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.sub-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 49px;
  border-right: 1px solid $input-border-color;

  &.not-available {
    background: $text-muted;
  }

  &.approved {
    background-color: $success;
  }

  &.rejected {
    background-color: $danger;
  }

  &.repeated {
    background-color: $primary;
  }

  &.new {
    background-color: $warning;
  }

  &.test-ok {
    background-color: #83ffbb;
  }

  &:last-child {
    border-right: none;
  }

  .homework {
    cursor: pointer;
  }

  .lesson-info {
    font-weight: bold;
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.9rem;
    font-weight: 500;
    &.homework {
      transition: background-color 0.3s ease;
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    .message-indicator {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0.8rem;
    }
  }
}

.dark-layout {
  .sub-cell {
    border-color: $theme-dark-border-color !important;
  }

  .not-available {
    background: $theme-dark-input-disabled-border-color !important;
  }
}
</style>
