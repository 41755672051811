<template>
  <div class="h-100">
    <table :class="{ 'hidden-block': collapsed }" class="sub-table">
      <tr>
        <lesson-view
          v-for="lesson in lessons"
          :key="lesson.id"
          :lesson="lesson"
          :homework="getHomework(lesson.id)"
          :student="student"
        />
      </tr>
    </table>
    <div
      class="sprint-info d-flex align-items-center justify-content-center"
      :class="{ 'hidden-block': !collapsed }"
    >
      <div
        v-if="existedDialogs.filter(d => d.status === 'approved').length !== 0"
        class="counter d-flex align-items-center"
      >
        <feather-icon class="text-success" size="15" icon="CheckIcon" />
        <span>
          {{ existedDialogs.filter(d => d.status === 'approved').length }}
        </span>
      </div>
      <div
        v-if="existedDialogs.filter(d => d.status === 'repeated').length !== 0"
        class="counter d-flex align-items-center"
      >
        <feather-icon class="text-primary" size="15" icon="RepeatIcon" />
        <span>
          {{ existedDialogs.filter(d => d.status === 'repeated').length }}
        </span>
      </div>
      <div
        v-if="existedDialogs.filter(d => d.status === 'rejected').length !== 0"
        class="counter d-flex align-items-center"
      >
        <feather-icon class="text-danger" size="15" icon="SlashIcon" />
        <span>
          {{ existedDialogs.filter(d => d.status === 'rejected').length }}
        </span>
      </div>
      <div
        v-if="existedDialogs.filter(d => d.status === 'none').length !== 0"
        class="counter d-flex align-items-center"
      >
        <feather-icon class="text-warning" size="15" icon="MessageSquareIcon" />
        <span>
          {{ existedDialogs.filter(d => d.status === 'none').length }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LessonView from './LessonView.vue'

export default {
  components: {
    LessonView,
  },
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean | undefined,
      required: true,
    },
  },
  computed: {
    existedDialogs() {
      return this.student.lessons.filter(lesson =>
        this.lessons.some(l => l.id === lesson.id),
      )
    },
  },
  methods: {
    getHomework(id) {
      return this.student.lessons.find(lesson => lesson.id === id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.hidden-block {
  opacity: 0;
  visibility: hidden;
}

.sub-table {
  table-layout: fixed;
  width: 100%;
  transition: 0.5s ease-in-out;
}

.sprint-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s ease-in-out;
}

.counter:not(:last-child) {
  margin-right: 10px;
}
</style>