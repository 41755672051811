<template>
  <div class="student-block d-flex position-relative">
    <div class="left-panel flex-shrink-0 d-flex flex-column">
      <div class="user-info d-flex align-items-center" :class="{ scrolled }">
        <b-avatar :src="student.avatarUrl" size="lg" class="mr-1" />
        <div class="flex-grow-1 overflow-hidden">
          <h6 class="name">
            {{ student.name }}
          </h6>
          <div class="contact">
            <span>
              {{ student.email }}
            </span>
          </div>
          <div v-if="student.telegram" class="contact">
            <span>
              <a :href="`https://t.me/${student.telegram}`" target="blank">
                <feather-icon icon="SendIcon" />
                {{ student.telegram }}
              </a>
            </span>
          </div>
        </div>
      </div>
      <vue-perfect-scrollbar class="lessons-list" ref="scrollarea">
        <transition-group name="sort">
          <lesson-view
            v-for="lesson in sortedLessons"
            :key="lesson.id"
            :class="{ selected: dialogId === lesson.id }"
            :lesson="lesson"
            @click.native="selectLesson(lesson.id)"
            v-height-fade.appear
          ></lesson-view>
        </transition-group>
      </vue-perfect-scrollbar>
    </div>

    <div class="flex-grow-1">
      <transition name="fade">
        <div
          v-if="lesson"
          key="chat"
          class="
            d-flex
            flex-column flex-grow-1
            overflow-hidden
            h-100
            position-relative
          "
        >
          <b-overlay rounded="sm" :show="chatPending" no-wrap />
          <div
            class="
              top-panel
              d-flex
              align-items-center
              overflow-hidden
              flex-shrink-0
            "
          >
            <h4 class="lesson-name mb-0 mx-1 flex-grow-1">
              {{ lessonTitle }}
            </h4>
            <b-button
              @click="acceptClick"
              :variant="
                lesson.isApprove && !lesson.isJustCreated && !lesson.isRepeated
                  ? 'success'
                  : 'outline-success'
              "
            >
              {{
                lesson.isApprove && !lesson.isJustCreated && !lesson.isRepeated
                  ? 'Принято'
                  : 'Принять'
              }}
            </b-button>
            <b-button
              @click="rejectClick"
              :variant="
                !lesson.isApprove && !lesson.isJustCreated && !lesson.isRepeated
                  ? 'danger'
                  : 'outline-danger'
              "
              class="mx-50"
            >
              {{
                !lesson.isApprove && !lesson.isJustCreated && !lesson.isRepeated
                  ? 'Отклонено'
                  : 'Отклонить'
              }}
            </b-button>
          </div>
          <chat ref="chat" class="flex-grow-1" />
        </div>
        <div
          v-else
          key="empty"
          class="
            d-flex
            flex-grow-1
            h-100
            flex-column
            align-items-center
            justify-content-center
          "
        >
          <div>
            <feather-icon size="30" icon="MessageSquareIcon" />
          </div>
          <h1>Выберите чат</h1>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Chat from '@/components/homeworkReview/Chat.vue'
import LessonView from '@/components/homeworkReview/LessonView.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BAvatar, BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    Chat,
    LessonView,
    BButton,
    BAvatar,
    BOverlay,
    VuePerfectScrollbar,
  },
  directives: {
    'height-fade': heightFade,
  },
  data: () => ({ scrolled: false }),
  computed: {
    ...mapGetters({
      student: 'homeworkReviewStore/getStudent',
      lessons: 'homeworkReviewStore/getLessons',
      lessonTitle: 'homeworkReviewStore/getLessonTitle',
      dialogId: 'homeworkReviewStore/getDialogId',
      lesson: 'homeworkReviewStore/getLesson',
      chatPending: 'homeworkReviewStore/chatPending',
      isDark: 'appConfig/isDark',
    }),
    sortedLessons() {
      return this.lessons
        .sort((l1, l2) => l2.lastMessageDate - l1.lastMessageDate)
        .sort((l1, l2) => {
          return l1.viewed === l2.viewed ? 0 : l1.viewed ? 1 : -1
        })
    },
  },
  mounted() {
    this.$refs.scrollarea.$el.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    this.$refs.scrollarea.$el.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    scrollHandler() {
      this.scrolled = this.$refs.scrollarea.$el.scrollTop !== 0
    },
    async selectLesson(id) {
      this.selectedLessonId = id
      await this.$store.dispatch('homeworkReviewStore/fetchDialog', id)
      this.$store.dispatch('homeworkReviewStore/updateRouteQuery')
      this.$refs.chat?.scrollDown()
    },
    async acceptClick() {
      const result = await this.$bvModal.msgBoxConfirm(
        'Подвердите изменение статуса на "Принято"',
        {
          title: 'Требуется подтверждение',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Продолжить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        },
      )
      if (result) {
        await this.$store.dispatch(
          'homeworkReviewStore/changeDialogueState',
          'success',
        )
      }
    },
    async rejectClick() {
      const result = await this.$bvModal.msgBoxConfirm(
        'Подвердите изменение статуса на "Отклонено"',
        {
          title: 'Требуется подтверждение',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Продолжить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        },
      )
      if (result) {
        await this.$store.dispatch(
          'homeworkReviewStore/changeDialogueState',
          'rejected',
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.fade-enter-active,
.fade-leave-active,
.fade-leave,
.fade-active,
.fade-enter {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.sort-enter,
.sort-leave {
  opacity: 0;
}

.sort-move,
.sort-enter-active,
.sort-leave-active {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.student-block {
  overflow: hidden;

  .top-panel {
    border-bottom: 1px solid $input-border-color;
    padding: 5px;
    height: 73px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: wrap;

    .lesson-name {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .left-panel {
    border-right: 1px solid $input-border-color;
    width: 300px;
    overflow: hidden;

    .lessons-list {
      overflow: hidden;
    }

    .user-info {
      padding: 10px;
      height: 72px;

      &.scrolled {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
      }

      .name {
        font-weight: bold;
        margin-bottom: 0;
        max-height: 32px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .contact {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-of-type {
          font-size: 0.857rem;
        }
      }
    }
  }
}

.dark-layout {
  .top-panel,
  .left-panel {
    border-color: $theme-dark-border-color;
  }
}
h1 {
  font-size: 1rem;
  margin: 0.5rem 0 0;
}
</style>
