var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat d-flex flex-column overflow-hidden"},[_c('div',{staticClass:"chatarea flex-grow-1 d-flex flex-column justify-content-end overflow-hidden"},[_c('vue-perfect-scrollbar',{ref:"scrollarea",staticClass:"overflow-hidden pb-1"},_vm._l((_vm.messages),function(message){return _c('div',{key:message.id},[(message.newMessagesLabel)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"unread-messages-label"},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}}),_c('span',{staticClass:"mx-50"},[_vm._v("непрочитанные сообщения")]),_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}})],1)]):(message.isStatus)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"unread-messages-label px-1 d-flex flex-column align-items-center",class:{
              'bg-success': message.status === 'approved',
              'bg-danger': message.status === 'rejected',
            }},[_c('small',[_vm._v(_vm._s(_vm.formatChatDate(message.created_at)))]),_c('small',[_vm._v(" "+_vm._s(((message.name) + " сменил(а) статус на \"" + (message.status === 'approved' ? 'Принято' : 'Отклонено') + "\""))+" ")])])]):_c('div',{staticClass:"message d-flex",class:{ 'message-mentor': !message.isStudent }},[_c('b-avatar',{staticClass:"mx-50 flex-shrink-0",attrs:{"id":("message-" + (message.id)),"src":message.isStudent ? _vm.student.avatarUrl : message.avatarUrl}}),_c('b-tooltip',{attrs:{"target":("message-" + (message.id)),"delay":{ show: 500, hide: 0 }}},[_vm._v(" "+_vm._s(message.isStudent ? _vm.student.name : message.name)+" ")]),_c('div',{staticClass:"message-body"},[_c('div',{domProps:{"innerHTML":_vm._s(message.body)}}),_c('div',{staticClass:"text-muted message-date"},[_c('small',[_vm._v(" "+_vm._s(_vm.formatChatDate(message.created_at))+" ")]),(message.isVideoReview)?_c('feather-icon',{attrs:{"size":"12","icon":"VideoIcon"}}):_vm._e()],1)]),_c('div',{staticClass:"mx-50 dummy"})],1)])}),0)],1),(!_vm.lesson.isApprove)?_c('div',{staticClass:"message-block position-relative flex-shrink-0"},[_c('quill-editor',{staticClass:"text-editor",attrs:{"options":{
        placeholder: 'Введите сообщение',
        modules: {
          toolbar: _vm.quillTollbar,
          keyboard: {
            bindings: {
              'list autofill': {
                prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/,
              },
            },
          },
        },
        formats: _vm.quillEditorFormats,
      }},on:{"change":function($event){return _vm.onEditorChange($event)}},nativeOn:{"keydown":function($event){return _vm.keyDownHandle.apply(null, arguments)}},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-center px-50 pb-50 position-relative"},[_c('b-form-checkbox',{staticClass:"m-0 video-review-checkbox",model:{value:(_vm.videoReview),callback:function ($$v) {_vm.videoReview=$$v},expression:"videoReview"}},[_vm._v(" Это видеоразбор ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.busy,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{staticClass:"send-button",attrs:{"variant":"primary","disabled":_vm.trimmedInput === '' || _vm.symbolsCount > _vm.maxSymbolsCount},on:{"click":_vm.sendMessage}},[_c('span',[_vm._v("Отправить")])])],1),_c('div',{staticClass:"symbols-counter",class:{ 'text-danger': _vm.symbolsCount > _vm.maxSymbolsCount }},[_vm._v(" "+_vm._s((_vm.symbolsCount + "/" + _vm.maxSymbolsCount))+" ")])],1)],1):_c('div',{staticClass:"p-50"},[_vm._v(" Вы не можете отправлять сообщения, если задание принято ")])])}
var staticRenderFns = []

export { render, staticRenderFns }