const dayOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
const months = [
  'янв',
  'фев',
  'март',
  'апр',
  'май',
  'июнь',
  'июль',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
]

export function getMonthString(month) {
  return months[parseInt(month) - 1]
}

export function formatChatDate(date) {
  if (typeof date === 'string') date = new Date(date)
  const currentDate = new Date()
  const day = currentDate.getDay()
  const diff = currentDate.getDate() - day + (day == 0 ? -6 : 1)
  const weekStartTemp = new Date(currentDate.setDate(diff))
  const weekStart = new Date(weekStartTemp.setHours(0, 0, 0, 0))
  const currentYear = currentDate.getFullYear()
  const messageYear = date.getFullYear()
  const yearString = currentYear === messageYear ? '' : ` ${messageYear}`
  const dayOfWeekString =
    date - weekStart >= 0 ? `${dayOfWeek[date.getDay()]}, ` : ''
  const dateString = date.toLocaleString('ru-RU')

  return `${dayOfWeekString}${dateString.substr(0, 2)} ${getMonthString(
    dateString.substr(3, 2),
  )}${yearString}, ${dateString.substr(12, 5)}`
}
