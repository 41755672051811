<template>
  <b-card>
    <filter-panel @openTableClick="$refs.homeworksTableModal.show()" />
    <div class="flex-grow-1 overflow-hidden d-flex">
      <students-panel
        :collapsed="chatMaximized"
        @collapse="chatMaximized = !chatMaximized"
        class="flex-shrink-0"
      />
      <div class="flex-grow-1 overflow-hidden h-100 position-relative">
        <BOverlay no-wrap :show="pendingStudents" />
        <transition name="fade">
          <student-block key="student" v-if="student" class="h-100" />
          <div
            key="empty"
            v-else
            class="
              d-flex
              h-100
              flex-column
              align-items-center
              justify-content-center
              review-desc
            "
          >
            <div class="review-desc__icon">
              <img src="@/assets/images/emoji/man-raising-hand.png" />
            </div>
            <h1>Выберите студента</h1>
          </div>
        </transition>
      </div>
    </div>
    <homeworks-table-modal ref="homeworksTableModal" />
  </b-card>
</template>

<script>
import FilterPanel from '@/components/homeworkReview/FilterPanel.vue'
import StudentsPanel from '@/components/homeworkReview/StudentsPanel.vue'
import StudentBlock from '@/components/homeworkReview/StudentBlock.vue'
import HomeworksTableModal from '@/components/homeworkReview/HomeworksTableModal.vue'
import { BCard } from 'bootstrap-vue'
import { BOverlay } from '@/components/bootstrap'
import { mapGetters } from 'vuex'
import { userStore } from '@/store'

export default {
  components: {
    FilterPanel,
    StudentsPanel,
    StudentBlock,
    HomeworksTableModal,
    BCard,
    BOverlay,
  },
  data: () => ({ chatMaximized: false }),
  computed: {
    ...mapGetters({
      student: 'homeworkReviewStore/getStudent',
      pendingStudents: 'homeworkReviewStore/getPendingStudents',
    }),
  },
  async mounted() {
    this.$store.commit('homeworkReviewStore/setUser', userStore.user)

    if (Object.values(this.$route.query).length === 0) {
      const queryObject = await this.$store.dispatch(
        'homeworkReviewStore/updateRouteQuery',
      )

      if (Object.values(queryObject).length !== 0) return
    }

    await this.$store.dispatch('homeworkReviewStore/fetchCoursesAndStreams')
    this.$store.commit('homeworkReviewStore/setRoute', this.$route.path)

    await this.$store.dispatch(
      'homeworkReviewStore/loadDataFromQuery',

      this.$route.query,
    )
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.fade-enter-active,
.fade-leave-active,
.fade-leave,
.fade-active,
.fade-enter {
  opacity: 0;
  transition: 0.3s;
}

.card {
  overflow: hidden;
  margin: 0;
  height: calc(var(--vh, 1vh) * 100 - 11.1rem);

  border: 1px solid $input-border-color;
}

.card-body {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dark-layout {
  .card {
    border-color: $theme-dark-border-color;
  }
}

.review-desc {
  &__icon {
    width: 30px;
    height: 30px;
    img {
      display: block;
      max-width: 100%;
      object-fit: contain;
    }
  }
  h1 {
    font-size: 1rem;
    margin: 0.5rem 0 0;
  }
}
</style>
