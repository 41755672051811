<template>
  <div class="chat d-flex flex-column overflow-hidden">
    <div
      class="chatarea flex-grow-1 d-flex flex-column justify-content-end overflow-hidden"
    >
      <vue-perfect-scrollbar class="overflow-hidden pb-1" ref="scrollarea">
        <div v-for="message in messages" :key="message.id">
          <div
            v-if="message.newMessagesLabel"
            class="d-flex justify-content-center"
          >
            <div class="unread-messages-label">
              <feather-icon icon="ArrowDownIcon" />
              <span class="mx-50">непрочитанные сообщения</span>
              <feather-icon icon="ArrowDownIcon" />
            </div>
          </div>
          <div
            v-else-if="message.isStatus"
            class="d-flex justify-content-center"
          >
            <div
              class="unread-messages-label px-1 d-flex flex-column align-items-center"
              :class="{
                'bg-success': message.status === 'approved',
                'bg-danger': message.status === 'rejected',
              }"
            >
              <small>{{ formatChatDate(message.created_at) }}</small>
              <small>
                {{
                  `${message.name} сменил(а) статус на "${
                    message.status === 'approved' ? 'Принято' : 'Отклонено'
                  }"`
                }}
              </small>
            </div>
          </div>
          <div
            v-else
            class="message d-flex"
            :class="{ 'message-mentor': !message.isStudent }"
          >
            <b-avatar
              :id="`message-${message.id}`"
              :src="message.isStudent ? student.avatarUrl : message.avatarUrl"
              class="mx-50 flex-shrink-0"
            />
            <b-tooltip
              :target="`message-${message.id}`"
              :delay="{ show: 500, hide: 0 }"
            >
              {{ message.isStudent ? student.name : message.name }}
            </b-tooltip>
            <div class="message-body">
              <div v-html="message.body" />
              <div class="text-muted message-date">
                <small>
                  {{ formatChatDate(message.created_at) }}
                </small>
                <feather-icon
                  v-if="message.isVideoReview"
                  size="12"
                  icon="VideoIcon"
                />
              </div>
            </div>
            <div class="mx-50 dummy"></div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <div
      v-if="!lesson.isApprove"
      class="message-block position-relative flex-shrink-0"
    >
      <quill-editor
        class="text-editor"
        v-model="input"
        @keydown.native="keyDownHandle"
        @change="onEditorChange($event)"
        :options="{
          placeholder: 'Введите сообщение',
          modules: {
            toolbar: quillTollbar,
            keyboard: {
              bindings: {
                'list autofill': {
                  prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/,
                },
              },
            },
          },
          formats: quillEditorFormats,
        }"
      />
      <div
        class="d-flex justify-content-between align-items-center px-50 pb-50 position-relative"
      >
        <b-form-checkbox
          class="m-0 video-review-checkbox"
          v-model="videoReview"
        >
          Это видеоразбор
        </b-form-checkbox>
        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            variant="primary"
            class="send-button"
            @click="sendMessage"
            :disabled="trimmedInput === '' || symbolsCount > maxSymbolsCount"
          >
            <span>Отправить</span>
          </b-button>
        </b-overlay>
        <div
          class="symbols-counter"
          :class="{ 'text-danger': symbolsCount > maxSymbolsCount }"
        >
          {{ `${symbolsCount}/${maxSymbolsCount}` }}
        </div>
      </div>
    </div>
    <div v-else class="p-50">
      Вы не можете отправлять сообщения, если задание принято
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { BButton, BAvatar, BTooltip, BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { formatChatDate } from '@/helpers/dateFormatter'

export default {
  components: {
    BButton,
    BAvatar,
    BTooltip,
    BFormCheckbox,
    VuePerfectScrollbar,
    quillEditor,
  },
  props: {},
  data: () => ({
    busy: false,
    input: '',
    videoReview: false,
    maxSymbolsCount: 5000,
    symbolsCount: 0,
    quillEditorFormats: [
      'bold',
      'italic',
      'link',
      'strike',
      'underline',
      'indent',
      'list',
    ],
    quillTollbar: [
      'bold',
      'italic',
      'underline',
      'strike',
      { indent: '-1' },
      { indent: '+1' },
      { list: 'ordered' },
      { list: 'bullet' },
      'link',
    ],
  }),
  computed: {
    ...mapGetters({
      messages: 'homeworkReviewStore/getMessages',
      student: 'homeworkReviewStore/getStudent',
      lesson: 'homeworkReviewStore/getLesson',
    }),
    trimmedInput() {
      let value = this.input
        .replaceAll('class="ql-indent-1"', 'style="padding-left: 3rem"')
        .replaceAll('class="ql-indent-2"', 'style="padding-left: 6rem"')
        .replaceAll('class="ql-indent-3"', 'style="padding-left: 9rem"')
        .replaceAll('class="ql-indent-4"', 'style="padding-left: 12rem"')
        .replaceAll('class="ql-indent-5"', 'style="padding-left: 15rem"')
        .replaceAll('class="ql-indent-6"', 'style="padding-left: 18rem"')
        .replaceAll('class="ql-indent-7"', 'style="padding-left: 21rem"')
        .replaceAll('class="ql-indent-8"', 'style="padding-left: 24rem"')

      let split = value
        .split('<p')
        .map(str => (str.indexOf('</p>') !== -1 ? `<p${str}` : str))

      let firstIndex = null
      let lastIndex = 0

      for (let i = 0; i < split.length; i++) {
        if (split[i] !== '<p><br></p>' && split[i] !== '') {
          if (!firstIndex) {
            firstIndex = i
          }
          lastIndex = i
        }
      }

      return split
        .slice(firstIndex, lastIndex + 1)
        .reduce((acc, str) => acc + str)
    },
  },
  mounted() {
    this.scrollDown()
  },
  methods: {
    formatChatDate,
    async sendMessage() {
      if (this.input === '' || this.symbolsCount > this.maxSymbolsCount) return
      this.busy = true

      const message = this.trimmedInput
      const isVideoReview = this.videoReview

      this.input = ''
      this.videoReview = false

      await this.$store.dispatch('homeworkReviewStore/sendMessage', {
        message,
        isVideoReview,
      })
      this.scrollDown()
      this.busy = false
    },
    keyDownHandle(e) {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) this.sendMessage()
    },
    scrollDown() {
      this.$refs.scrollarea.$el.scrollTop =
        this.$refs.scrollarea.$el.scrollHeight
    },
    onEditorChange(e) {
      this.symbolsCount = e.quill.getLength() - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.message-block .text-editor {
  .ql-toolbar {
    border: none;
  }

  .ql-container {
    border-radius: 0;
    border: none;
    font-family: Montserrat, Helvetica, Arial, serif;

    .ql-editor {
      max-height: 20vh;
      p {
        word-break: break-word;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 9999px;
        background-color: $input-border-color;

        &:hover {
          background-color: $body-color;
        }
      }
      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }
  }
}

.message-mentor a {
  color: white;
  text-decoration: underline;
  display: block;
  max-width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-body {
  > div {
    word-break: break-word;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100% !important;
    height: 100% !important;
  }
  a {
    display: block;
    max-width: 100%;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin-bottom: 0;
    word-break: break-word;
  }
}

.dark-layout {
  a {
    &:hover {
      color: #fff;
    }
  }
  .message-block .text-editor .ql-container .ql-editor {
    &::-webkit-scrollbar-thumb {
      background-color: $theme-dark-border-color;

      &:hover {
        background-color: $body-color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.chat {
  .chatarea {
    background-image: url($chat-bg-light);
    background-color: #f2f0f7;
    background-repeat: repeat;
    background-size: 210px;

    .unread-messages-label {
      border-radius: 999px;
      padding: 3px 10px;
      margin: 5px 0;
      background-color: $body-color;
      color: white;
    }

    .message {
      margin: 5px;

      .dummy {
        width: 35px;
        flex-shrink: 0;
      }

      .message-body {
        box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
        background: white;
        border-radius: 5px;
        padding: 10px;

        p {
          margin-bottom: 0;
        }
      }

      &.message-mentor {
        flex-direction: row-reverse;

        .message-body {
          color: white;
          background: linear-gradient(to right, $primary, rgba($primary, 0.8));

          a {
            color: white !important;
          }
        }

        .message-date {
          text-align: right;
        }
      }
    }
  }

  .message-block {
    border-top: 1px solid $input-border-color;

    .send-button {
      z-index: 1;
      bottom: 5px;
      right: 5px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      padding: auto;
      height: 30px;
    }

    .text-editor {
      display: flex;
      flex-direction: column-reverse;
      border-radius: 0;
    }
  }
}

.symbols-counter {
  position: absolute;
  top: -20px;
  right: 10px;
  font-size: 13px;
}

.dark-layout {
  .chat {
    .chatarea {
      background-image: url($chat-bg-dark);
      background-color: #1e232f;
    }

    .message-block {
      border-top-color: $theme-dark-border-color;
    }
    .message {
      .message-body {
        background-color: $theme-dark-input-bg;
      }

      &.message-mentor {
        .message-date {
          color: $theme-dark-body-color !important;
        }
      }
    }
  }
}

.video-review-checkbox {
  font-size: 0.857rem;
}
</style>
