<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="mr-2">
      <div class="d-flex justify-content-between">
        <span class="d-flex align-items-center">
          <feather-icon class="text-success" size="15" icon="CheckIcon" />
          <span class="mr-1">
            {{ lessons.filter(lesson => lesson.status === 'approved').length }}
          </span>
        </span>
        <span class="d-flex align-items-center">
          <feather-icon class="text-primary" size="15" icon="RepeatIcon" />
          <span>
            {{ lessons.filter(lesson => lesson.status === 'repeated').length }}
          </span>
        </span>
      </div>
      <div class="d-flex justify-content-between">
        <span class="d-flex align-items-center">
          <feather-icon class="text-danger" size="15" icon="SlashIcon" />
          <span class="mr-1">
            {{ lessons.filter(lesson => lesson.status === 'rejected').length }}
          </span>
        </span>
        <span class="d-flex align-items-center">
          <feather-icon
            class="text-warning"
            size="15"
            icon="MessageSquareIcon"
          />
          <span>
            {{ lessons.filter(lesson => lesson.status === 'none').length }}
          </span>
        </span>
      </div>
    </div>
    <span>
      {{ `${successPercent}%` }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    lessons: {
      type: Array,
      required: true,
    },
    studentsCount: {
      type: Number,
      required: true,
    },
    lessonsCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    successPercent() {
      if (this.studentsCount === 0 || this.lessonsCount === 0) return 0

      const length = this.lessons.filter(
        lesson => lesson.status === 'approved',
      ).length

      return Math.ceil(
        (length / (this.studentsCount * this.lessonsCount)) * 100,
      )
    },
  },
}
</script>

