<template>
  <div class="d-flex table-wrapper position-relative">
    <table
      class="homework-table first-column transition"
      :class="{ 'table-shadow': !scrolledLeft }"
    >
      <thead class="header">
        <tr class="homework-row">
          <th class="homework-cell">
            <span>
              {{
                `${students.length} ${declination(students.length, [
                  'студент',
                  'студента',
                  'студентов',
                ])}`
              }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody ref="firstColumnBody" class="body">
        <tr
          v-for="(student, index) in sortedStudents"
          :key="student.id"
          class="homework-row position-relative"
        >
          <td class="homework-cell p-50">
            <div class="d-flex overflow-hidden align-items-center">
              <span :style="{ width: '30px' }">
                {{ index + 1 }}
              </span>
              <b-avatar :src="student.avatarUrl" class="mr-50" />
              <div class="student-data d-flex flex-column overflow-hidden">
                <h6 class="m-0 student-text">
                  {{ student.name }}
                </h6>
                <small class="student-text">
                  {{ student.email }}
                </small>
              </div>
            </div>
          </td>
          <div v-if="student.refund" class="refunded" />
        </tr>
      </tbody>
      <tfoot class="footer">
        <tr class="homework-row">
          <th class="homework-cell">
            <stats
              :lessons="dialogs"
              :studentsCount="studentsCount"
              :lessonsCount="
                sprints.reduce(
                  (val, sprint) =>
                    val +
                    sprint.lessons.filter(
                      lesson => lesson.date_start < new Date(),
                    ).length,
                  0,
                )
              "
            />
          </th>
        </tr>
      </tfoot>
    </table>

    <table class="homework-table lessons-block">
      <thead
        class="header transition"
        ref="tableHead"
        :class="{ 'table-shadow': !scrolledTop }"
      >
        <tr class="homework-row">
          <th
            v-for="(sprint, index) in sprints"
            :key="sprint.id"
            class="homework-cell"
            :class="{ collapsed: collapsedState[sprint.id] }"
            :style="
              collapsedState[sprint.id]
                ? ''
                : `max-width: ${
                    cellWidth * sprint.lessons.length
                  }px; min-width: ${cellWidth * sprint.lessons.length}px;`
            "
          >
            <div
              @click="toogleSprint(sprint.id)"
              class="d-flex flex-column h-100"
            >
              <h5
                class="m-0 flex-grow-1 d-flex align-items-center justify-content-center no-wrap"
              >
                {{ `${index + 1}. ${sprint.title.ru}` }}
              </h5>
              <div
                :class="{ 'hidden-block': !collapsedState[sprint.id] }"
                class="lessons-count"
              >
                {{
                  `${sprint.lessons.length} ${declination(
                    sprint.lessons.length,
                    ['урок', 'урока', 'уроков'],
                  )}`
                }}
              </div>
              <table
                :class="{ 'hidden-block': collapsedState[sprint.id] }"
                class="sub-table"
              >
                <tr>
                  <td
                    v-for="(lesson, index) in sprint.lessons"
                    :key="lesson.id"
                    class="sub-cell"
                    :class="{ 'not-available': lesson.date_start > new Date() }"
                    :id="`lesson-${lesson.id}`"
                  >
                    <span>
                      {{ `${index + 1}. ${lesson.title}` }}
                    </span>
                    <div class="small">
                      {{ getHomeworkTypeTitle(lesson.type) }}
                    </div>
                    <b-tooltip
                      :target="`lesson-${lesson.id}`"
                      :delay="{ show: 1000, hide: 0 }"
                    >
                      {{ lesson.title }}
                    </b-tooltip>
                  </td>
                </tr>
              </table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="body" ref="tableBody">
        <tr
          v-for="student in sortedStudents"
          :key="student.id"
          class="homework-row position-relative"
        >
          <td
            class="homework-cell"
            v-for="sprint in sprints"
            :key="sprint.id"
            :class="{ collapsed: collapsedState[sprint.id] }"
            :style="
              collapsedState[sprint.id]
                ? ''
                : `max-width: ${
                    cellWidth * sprint.lessons.length
                  }px; min-width: ${cellWidth * sprint.lessons.length}px;`
            "
          >
            <sprint-view
              :collapsed="collapsedState[sprint.id]"
              :lessons="sprint.lessons"
              :student="student"
            />
          </td>
          <div v-if="student.refund" class="refunded" />
        </tr>
      </tbody>
      <tfoot
        class="footer transition"
        ref="tableFooter"
        :class="{ 'table-shadow': !scrolledBottom }"
      >
        <tr class="homework-row">
          <th
            class="homework-cell"
            v-for="sprint in sprints"
            :key="sprint.id"
            :class="{ collapsed: collapsedState[sprint.id] }"
            :style="
              collapsedState[sprint.id]
                ? ''
                : `max-width: ${
                    cellWidth * sprint.lessons.length
                  }px; min-width: ${cellWidth * sprint.lessons.length}px;`
            "
          >
            <table
              :class="{ 'hidden-block': collapsedState[sprint.id] }"
              class="sub-table"
            >
              <tr>
                <td
                  v-for="lesson in sprint.lessons"
                  :key="lesson.id"
                  class="sub-cell"
                  :class="{ 'not-available': lesson.date_start > new Date() }"
                >
                  <stats
                    :lessons="dialogs.filter(dialog => dialog.id === lesson.id)"
                    :studentsCount="studentsCount"
                    :lessonsCount="1"
                  />
                </td>
              </tr>
            </table>

            <stats
              class="sprint-stats"
              :class="{ 'hidden-block': !collapsedState[sprint.id] }"
              :lessons="
                dialogs.filter(dialog =>
                  sprint.lessons.some(lesson => lesson.id === dialog.id),
                )
              "
              :studentsCount="studentsCount"
              :lessonsCount="
                sprint.lessons.filter(lesson => lesson.date_start < new Date())
                  .length
              "
            />
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { BAvatar, BTooltip } from 'bootstrap-vue'
import SprintView from '@/components/homeworkReview/table/SprintView.vue'
import Stats from '@/components/homeworkReview/table/Stats.vue'
import { mapGetters } from 'vuex'
import declination from '@/helpers/declination'

export default {
  components: {
    BAvatar,
    BTooltip,
    SprintView,
    Stats,
  },
  props: {
    sort: {
      type: String,
      default: 'best',
    },
  },
  data: () => ({
    toogle: true,
    scrolledTop: true,
    scrolledBottom: true,
    scrolledLeft: true,
    collapsedState: [],
    cellWidth: 150,
    isSyncingLeftScroll: false,
    isSyncingRightScroll: false,
  }),
  computed: {
    ...mapGetters({
      sprints: 'homeworkReviewStore/getSprints',
      students: 'homeworkReviewStore/getStudentsForTable',
    }),
    dialogs() {
      return this.students
        .filter(student => !student.refund)
        .reduce((array, student) => array.concat(student.lessons), [])
    },
    studentsCount() {
      return this.students.filter(student => !student.refund).length
    },
    sortedStudents() {
      switch (this.sort) {
        case 'bad':
          return this.students.sort(
            (s1, s2) =>
              s1.lessons.filter(lesson => lesson.status === 'approved').length -
              s2.lessons.filter(lesson => lesson.status === 'approved').length,
          )

        case 'best':
          return this.students.sort(
            (s1, s2) =>
              s2.lessons.filter(lesson => lesson.status === 'approved').length -
              s1.lessons.filter(lesson => lesson.status === 'approved').length,
          )
      }
    },
  },
  watch: {
    students: function () {
      this.scrolledBottom =
        this.$refs.tableBody.scrollHeight - this.$refs.tableBody.scrollTop ===
        this.$refs.tableBody.clientHeight
    },
  },
  mounted() {
    this.$refs.tableBody.addEventListener('scroll', this.synchronizeScroll)
    this.$refs.firstColumnBody.addEventListener(
      'scroll',
      this.synchronizeScrollFirstColumn,
    )
  },
  beforeDestroy() {
    this.$refs.tableBody.removeEventListener('scroll', this.synchronizeScroll)
    this.$refs.firstColumnBody.removeEventListener(
      'scroll',
      this.synchronizeScrollFirstColumn,
    )
  },
  methods: {
    declination,
    getHomeworkTypeTitle(value) {
      switch (value) {
        case 1:
          return 'ДЗ'
        case 2:
          return 'Тест'
        case 3:
          return 'Нет задания'
      }
    },
    synchronizeScroll(e) {
      const tbody = e.srcElement
      const scrollLeft = tbody.scrollLeft
      const scrollTop = tbody.scrollTop
      if (!this.isSyncingRightScroll) {
        this.isSyncingLeftScroll = true
        this.$refs.tableHead.scrollLeft = scrollLeft
        this.$refs.tableFooter.scrollLeft = scrollLeft
        this.$refs.firstColumnBody.scrollTop = scrollTop
      }
      this.scrolledLeft = scrollLeft === 0
      this.scrolledTop = scrollTop === 0
      this.scrolledBottom =
        tbody.scrollHeight - scrollTop === tbody.clientHeight

      this.isSyncingRightScroll = false
    },
    synchronizeScrollFirstColumn(e) {
      if (!this.isSyncingLeftScroll) {
        this.isSyncingRightScroll = true
        this.$refs.tableBody.scrollTop = e.srcElement.scrollTop
      }
      this.isSyncingLeftScroll = false
    },
    toogleSprint(sprintId) {
      this.$set(this.collapsedState, sprintId, !this.collapsedState[sprintId])
    },
  },
}
</script>

<style lang="scss">
.body .homework-row:last-child .sub-cell {
  height: 50px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

$first-row-width: 300px;

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.transition {
  transition: 0.2s ease-in-out;
}

.hidden-block {
  opacity: 0;
  visibility: hidden;
}

.table-wrapper {
  overflow: hidden;
}

.homework-table {
  display: block;
  overflow: hidden;
  max-width: calc(100% - #{$first-row-width});
  border: 1px solid $input-border-color;

  td,
  th {
    margin: 0;
    padding: 0;
  }

  .sub-table {
    table-layout: fixed;
    width: 100%;
    transition: 0.5s ease-in-out;

    .sub-cell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
      border-right: 1px solid $input-border-color;
      > span {
        &:first-child {
          font-size: 0.9rem;
          font-weight: 500;
        }
      }

      &.not-available {
        background: $text-muted;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  &.first-column {
    width: $first-row-width;
    background: white;
    position: relative;
    z-index: 10;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    overflow-x: hidden;

    .body {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    .homework-cell {
      width: $first-row-width;
    }
  }

  &.lessons-block {
    border-left: none;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .header {
      cursor: pointer;
    }

    .homework-row {
      flex-flow: nowrap;
    }

    .homework-cell {
      min-width: 800px;
      max-width: 800px;
      overflow: hidden;
      border-right: 1px solid $input-border-color;
      transition: 0.5s ease-in-out;

      &:last-child {
        border-right: none;
      }

      &.collapsed {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }

  .header .homework-row {
    height: 70px;
    overflow: hidden;
    border-bottom: 1px solid $input-border-color;
    text-align: center;
    background: #f2f0f7;

    .homework-cell {
      height: 70px;
      position: relative;

      .lessons-count {
        width: 150px;
        position: absolute;
        top: 42px;
        left: calc(50% - 75px);
        transition: 0.5s ease-in-out;
      }
    }

    .sub-cell {
      border-top: 1px solid $input-border-color;
      height: 35px;
    }
  }

  .body {
    display: block;
    overflow: overlay;
    max-height: calc(90vh - 200px);
    border-collapse: collapse;

    .homework-cell {
      position: relative;
    }

    .homework-row {
      border-bottom: 1px solid $input-border-color;
      height: 50px;

      .refunded {
        background: red;
        opacity: 0.5;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
      }

      &:last-child {
        border-bottom: none;

        .sub-cell {
          background: red !important;
        }
      }

      &:first-child {
        border-top: none;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 9999px;
      background-color: $input-border-color;

      &:hover {
        background-color: $body-color;
      }
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .header,
  .footer {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .footer {
    font-size: 0.9rem;
    &::v-deep {
      svg.feather {
        margin-right: 4px;
      }
    }
    .homework-row {
      border-top: 1px solid $input-border-color;
      height: 55px;
      background: #f2f0f7;
    }

    .sprint-stats {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: 0.5s ease-in-out;
    }

    .sub-cell,
    .homework-cell {
      height: 55px;
      text-align: center;
      position: relative;
      h5 {
        font-size: 1rem;
        font-weight: 700;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .student-data {
    white-space: nowrap;
    width: calc(#{$first-row-width} - 90px);

    .student-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-shadow {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}

.dark-layout {
  .sub-cell.not-available {
    background: $theme-dark-input-disabled-border-color !important;
  }

  .body::-webkit-scrollbar-thumb {
    background-color: $body-color;

    &:hover {
      background-color: $input-border-color;
    }
  }

  .first-column {
    background: $theme-dark-input-bg;
  }

  .header,
  .footer {
    .homework-row {
      background: $theme-dark-body-bg;
    }
  }

  .homework-table,
  .sub-cell,
  .homework-cell,
  .homework-row {
    border-color: $theme-dark-border-color !important;
  }
}
</style>
