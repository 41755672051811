<template>
  <b-modal
    id="homeworks-table-modal"
    centered
    hide-header
    hide-footer
    scrollable
    size="xl"
  >
    <div class="d-flex mb-1 mt-50 justify-content-between">
      <div class="d-flex">
        <wayup-select
          v-model="selectedStream"
          class="select mr-50"
          placeholder="выберите поток"
          emptyFilterMessage="нет подходящих потоков"
          :options="streams"
          label="title"
          :compareById="true"
          :clearable="false"
          @change="onStreamChanged"
        />
        <wayup-select
          v-if="mentorsPermission"
          v-model="selectedMentor"
          class="select"
          placeholder="выберите наставника"
          emptyFilterMessage="нет подходящих наставников"
          :options="mentorOptions"
          label="name"
          :clearable="false"
          @change="onMentorChanged"
        >
          <template #option="{ name, studentsCount, successPercent }">
            <div class="d-flex align-items-center p-50">
              <div class="w-100">
                <div>
                  {{ name }}
                </div>
                <div>
                  <small class="mr-50">
                    {{
                      `${studentsCount} ${declination(studentsCount, [
                        'студент',
                        'студента',
                        'студентов',
                      ])}`
                    }}
                  </small>
                  <small>{{ `успеваемость ${successPercent}%` }}</small>
                </div>
              </div>
            </div>
          </template>
        </wayup-select>
      </div>
      <div class="d-flex">
        <wayup-select
          class="mr-50"
          v-model="sort"
          :options="sortOptions"
          :clearable="false"
        />
        <b-button @click="close" variant="outline-danger" class="btn-icon">
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
    </div>

    <b-overlay rounded="sm" :show="homeworkTablePending">
      <homeworks-table class="mb-50" :sort="sort.value" />
    </b-overlay>
  </b-modal>
</template>

<script>
import { BModal, BButton, BOverlay } from 'bootstrap-vue'
import HomeworksTable from '@/components/homeworkReview/table/HomeworksTable.vue'
import WayupSelect from '@/components/WayupSelect.vue'
import { mapGetters } from 'vuex'
import declination from '@/helpers/declination'
import { userStore } from '@/store'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    HomeworksTable,
    WayupSelect,
  },
  data: () => ({
    selectedStream: null,
    selectedMentor: null,
    sort: { label: 'сначала лучшие', value: 'best' },
    sortOptions: [
      { label: 'сначала лучшие', value: 'best' },
      { label: 'сначала худшие', value: 'bad' },
    ],
  }),
  computed: {
    ...mapGetters({
      streams: 'homeworkReviewStore/getStreamFilter',
      mentors: 'homeworkReviewStore/getMentors',
      homeworkTablePending: 'homeworkReviewStore/homeworkTablePending',
      isDark: 'appConfig/isDark',
    }),
    user() {
      return userStore.user
    },
    mentorsPermission() {
      return this.user.group.permissions['admin.homework.mentors.view']
    },
    mentorOptions() {
      if (this.mentors.length > 1)
        return [this.getMentorsSummary(), ...this.mentors]
      return this.mentors
    },
  },
  mounted() {
    this.sort = this.sortOptions[0]
  },
  methods: {
    declination,
    getMentorsSummary() {
      const studentsCount = this.mentors.reduce(
        (total, mentor) => total + mentor.studentsCount,
        0,
      )
      const successPercent =
        studentsCount === 0
          ? 0
          : Math.ceil(
              this.mentors.reduce(
                (total, mentor) =>
                  total + mentor.studentsCount * mentor.successPercent,
                0,
              ) / studentsCount,
            )

      return {
        name: 'все наставники',
        studentsCount,
        successPercent,
      }
    },
    show() {
      this.selectedStream = this.streams[0]
      this.onStreamChanged()

      this.$bvModal.show('homeworks-table-modal')
    },
    close() {
      this.$bvModal.hide('homeworks-table-modal')
    },
    async onStreamChanged() {
      this.$store.commit('homeworkReviewStore/setHomeworkTablePending', true)

      await this.$store.dispatch(
        'fetchSprintAndLessons',
        this.selectedStream.id,
      )
      await this.$store.dispatch(
        'homeworkReviewStore/fetchSprints',
        this.selectedStream.id,
      )
      await this.$store.dispatch(
        'homeworkReviewStore/fetchMentors',
        this.selectedStream.id,
      )
      if (this.mentors.length > 0) {
        this.selectedMentor = this.mentorOptions[0]
        this.onMentorChanged()
      }
    },
    async onMentorChanged() {
      this.$store.commit('homeworkReviewStore/setHomeworkTablePending', true)

      await this.$store.dispatch('homeworkReviewStore/fetchStudentsForTable', {
        streamId: this.selectedStream.id,
        mentorId: this.selectedMentor.id,
      })

      this.$store.commit('homeworkReviewStore/setHomeworkTablePending', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.select {
  width: 250px;
}
</style>
