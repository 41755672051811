var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex table-wrapper position-relative"},[_c('table',{staticClass:"homework-table first-column transition",class:{ 'table-shadow': !_vm.scrolledLeft }},[_c('thead',{staticClass:"header"},[_c('tr',{staticClass:"homework-row"},[_c('th',{staticClass:"homework-cell"},[_c('span',[_vm._v(" "+_vm._s(((_vm.students.length) + " " + (_vm.declination(_vm.students.length, [ 'студент', 'студента', 'студентов' ]))))+" ")])])])]),_c('tbody',{ref:"firstColumnBody",staticClass:"body"},_vm._l((_vm.sortedStudents),function(student,index){return _c('tr',{key:student.id,staticClass:"homework-row position-relative"},[_c('td',{staticClass:"homework-cell p-50"},[_c('div',{staticClass:"d-flex overflow-hidden align-items-center"},[_c('span',{style:({ width: '30px' })},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('b-avatar',{staticClass:"mr-50",attrs:{"src":student.avatarUrl}}),_c('div',{staticClass:"student-data d-flex flex-column overflow-hidden"},[_c('h6',{staticClass:"m-0 student-text"},[_vm._v(" "+_vm._s(student.name)+" ")]),_c('small',{staticClass:"student-text"},[_vm._v(" "+_vm._s(student.email)+" ")])])],1)]),(student.refund)?_c('div',{staticClass:"refunded"}):_vm._e()])}),0),_c('tfoot',{staticClass:"footer"},[_c('tr',{staticClass:"homework-row"},[_c('th',{staticClass:"homework-cell"},[_c('stats',{attrs:{"lessons":_vm.dialogs,"studentsCount":_vm.studentsCount,"lessonsCount":_vm.sprints.reduce(
                function (val, sprint) { return val +
                  sprint.lessons.filter(
                    function (lesson) { return lesson.date_start < new Date(); }
                  ).length; },
                0
              )}})],1)])])]),_c('table',{staticClass:"homework-table lessons-block"},[_c('thead',{ref:"tableHead",staticClass:"header transition",class:{ 'table-shadow': !_vm.scrolledTop }},[_c('tr',{staticClass:"homework-row"},_vm._l((_vm.sprints),function(sprint,index){return _c('th',{key:sprint.id,staticClass:"homework-cell",class:{ collapsed: _vm.collapsedState[sprint.id] },style:(_vm.collapsedState[sprint.id]
              ? ''
              : ("max-width: " + (_vm.cellWidth * sprint.lessons.length) + "px; min-width: " + (_vm.cellWidth * sprint.lessons.length) + "px;"))},[_c('div',{staticClass:"d-flex flex-column h-100",on:{"click":function($event){return _vm.toogleSprint(sprint.id)}}},[_c('h5',{staticClass:"m-0 flex-grow-1 d-flex align-items-center justify-content-center no-wrap"},[_vm._v(" "+_vm._s(((index + 1) + ". " + (sprint.title.ru)))+" ")]),_c('div',{staticClass:"lessons-count",class:{ 'hidden-block': !_vm.collapsedState[sprint.id] }},[_vm._v(" "+_vm._s(((sprint.lessons.length) + " " + (_vm.declination( sprint.lessons.length, ['урок', 'урока', 'уроков'] ))))+" ")]),_c('table',{staticClass:"sub-table",class:{ 'hidden-block': _vm.collapsedState[sprint.id] }},[_c('tr',_vm._l((sprint.lessons),function(lesson,index){return _c('td',{key:lesson.id,staticClass:"sub-cell",class:{ 'not-available': lesson.date_start > new Date() },attrs:{"id":("lesson-" + (lesson.id))}},[_c('span',[_vm._v(" "+_vm._s(((index + 1) + ". " + (lesson.title)))+" ")]),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.getHomeworkTypeTitle(lesson.type))+" ")]),_c('b-tooltip',{attrs:{"target":("lesson-" + (lesson.id)),"delay":{ show: 1000, hide: 0 }}},[_vm._v(" "+_vm._s(lesson.title)+" ")])],1)}),0)])])])}),0)]),_c('tbody',{ref:"tableBody",staticClass:"body"},_vm._l((_vm.sortedStudents),function(student){return _c('tr',{key:student.id,staticClass:"homework-row position-relative"},[_vm._l((_vm.sprints),function(sprint){return _c('td',{key:sprint.id,staticClass:"homework-cell",class:{ collapsed: _vm.collapsedState[sprint.id] },style:(_vm.collapsedState[sprint.id]
              ? ''
              : ("max-width: " + (_vm.cellWidth * sprint.lessons.length) + "px; min-width: " + (_vm.cellWidth * sprint.lessons.length) + "px;"))},[_c('sprint-view',{attrs:{"collapsed":_vm.collapsedState[sprint.id],"lessons":sprint.lessons,"student":student}})],1)}),(student.refund)?_c('div',{staticClass:"refunded"}):_vm._e()],2)}),0),_c('tfoot',{ref:"tableFooter",staticClass:"footer transition",class:{ 'table-shadow': !_vm.scrolledBottom }},[_c('tr',{staticClass:"homework-row"},_vm._l((_vm.sprints),function(sprint){return _c('th',{key:sprint.id,staticClass:"homework-cell",class:{ collapsed: _vm.collapsedState[sprint.id] },style:(_vm.collapsedState[sprint.id]
              ? ''
              : ("max-width: " + (_vm.cellWidth * sprint.lessons.length) + "px; min-width: " + (_vm.cellWidth * sprint.lessons.length) + "px;"))},[_c('table',{staticClass:"sub-table",class:{ 'hidden-block': _vm.collapsedState[sprint.id] }},[_c('tr',_vm._l((sprint.lessons),function(lesson){return _c('td',{key:lesson.id,staticClass:"sub-cell",class:{ 'not-available': lesson.date_start > new Date() }},[_c('stats',{attrs:{"lessons":_vm.dialogs.filter(function (dialog) { return dialog.id === lesson.id; }),"studentsCount":_vm.studentsCount,"lessonsCount":1}})],1)}),0)]),_c('stats',{staticClass:"sprint-stats",class:{ 'hidden-block': !_vm.collapsedState[sprint.id] },attrs:{"lessons":_vm.dialogs.filter(function (dialog) { return sprint.lessons.some(function (lesson) { return lesson.id === dialog.id; }); }
              ),"studentsCount":_vm.studentsCount,"lessonsCount":sprint.lessons.filter(function (lesson) { return lesson.date_start < new Date(); })
                .length}})],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }