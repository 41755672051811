<template>
  <div class="panel d-flex align-items-center">
    <b-row class="flex-grow-1">
      <b-col cols="3" class="px-1">
        <wayup-select
          v-model="selectedCourse"
          class="select"
          placeholder="Выберите курс"
          emptyFilterMessage="нет подходящих курсов"
          :options="courses"
          label="title"
          @input="updateRouteQuery"
        >
          <template #option="{ title, imageUrl, hex }">
            <div class="d-flex align-items-center course-option">
              <div
                class="
                  image-wrapper
                  d-flex
                  align-items-center
                  justify-content-center
                  mr-50
                "
                :style="`background: ${hex}`"
              >
                <img :src="imageUrl" alt="" />
              </div>
              <div class="title">
                {{ title }}
              </div>
            </div>
          </template>
          <template #selected-option="{ title, imageUrl, hex }">
            <div class="d-flex align-items-center course-option selected">
              <div
                class="
                  image-wrapper
                  d-flex
                  align-items-center
                  justify-content-center
                  mr-50
                "
                :style="`background: ${hex}`"
              >
                <img :src="imageUrl" alt="" />
              </div>
              <div class="title">
                {{ title }}
              </div>
            </div>
          </template>
        </wayup-select>
      </b-col>
      <b-col cols="3" class="px-1">
        <wayup-select
          v-model="selectedStreams"
          class="select"
          placeholder="выберите потоки"
          emptyFilterMessage="нет подходящих потоков"
          :disabled="!selectedCourse"
          :multiselect="true"
          :selectedOnTop="true"
          :closeOnSelect="false"
          :elementDeclination="['поток', 'потока', 'потоков']"
          :options="streams"
          label="title"
          @change="onStreamsChanged"
        >
          <template #option="{ title, dateStart }">
            <div class="d-flex align-items-center option">
              <div class="title">
                <div>
                  {{ title }}
                </div>
                <div class="bottom-line">
                  <feather-icon size="15" icon="CalendarIcon" />
                  <small>
                    {{ dateStart }}
                  </small>
                </div>
              </div>
            </div>
          </template>
        </wayup-select>
      </b-col>
      <b-col cols="3" class="px-1">
        <wayup-select
          v-model="selectedLessons"
          class="select"
          placeholder="выберите занятия"
          emptyFilterMessage="нет подходящих занятий"
          :disabled="selectedStreams.length === 0"
          :multiselect="true"
          :selectedOnTop="true"
          :closeOnSelect="false"
          :elementDeclination="['занятие', 'занятия', 'занятий']"
          :options="lessons"
          label="title"
          @change="onLessonsChanged"
        >
          <template #option="{ title, streamId }">
            <div class="d-flex align-items-center option">
              <div class="title">
                <div>
                  {{ title }}
                </div>
                <div class="bottom-line">
                  <small>
                    {{ streamNameFromId(streamId) }}
                  </small>
                </div>
              </div>
            </div>
          </template>
        </wayup-select>
      </b-col>
      <b-col v-if="mentorsPermission" cols="3" class="px-1">
        <wayup-select
          v-model="selectedMentors"
          class="select"
          placeholder="выберите наставников"
          emptyFilterMessage="нет подходящих наставников"
          :disabled="selectedStreams.length === 0"
          :multiselect="true"
          :selectedOnTop="true"
          :closeOnSelect="false"
          :elementDeclination="['наставник', 'наставника', 'наставников']"
          :options="mentors"
          label="name"
          @change="onMentorsChanged"
        >
          <template #option="{ name, avatarUrl, email }">
            <div class="d-flex align-items-center option">
              <b-avatar :src="avatarUrl" class="mr-50" />
              <div class="title">
                <div>
                  {{ name }}
                </div>
                <div class="bottom-line">
                  <small>
                    {{ email }}
                  </small>
                </div>
              </div>
            </div>
          </template>
        </wayup-select>
      </b-col>
    </b-row>
    <b-button
      class="ml-1 table-button py-0"
      variant="primary"
      :disabled="selectedStreamsVuex.length === 0"
      @click="$emit('openTableClick')"
    >
      <feather-icon size="25" icon="LayoutIcon" />
    </b-button>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import WayupSelect from '@/components/WayupSelect.vue'
import { BButton, BRow, BCol, BAvatar } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { userStore } from '@/store'

export default {
  components: { vSelect, WayupSelect, BButton, BRow, BCol, BAvatar },
  data: () => ({}),
  computed: {
    ...mapGetters({
      courses: 'homeworkReviewStore/getCourses',
      streams: 'homeworkReviewStore/getFilteredStreams',
      lessons: 'homeworkReviewStore/getFilteredLessons',
      mentors: 'homeworkReviewStore/getFilteredMentors',
      selectedCourseVuex: 'homeworkReviewStore/getCourseFilter',
      selectedStreamsVuex: 'homeworkReviewStore/getStreamFilter',
      selectedLessonsVuex: 'homeworkReviewStore/getLessonFilter',
      selectedMentorsVuex: 'homeworkReviewStore/getMentorFilter',
      student: 'homeworkReviewStore/getStudent',
      students: 'homeworkReviewStore/getStudents',
      detailedLessons: 'homeworkReviewStore/getLessons',
      dialogId: 'homeworkReviewStore/getDialogId',
    }),
    user() {
      return userStore.user
    },
    selectedCourse: {
      get() {
        return this.selectedCourseVuex
      },
      set(value) {
        this.$store.commit('homeworkReviewStore/setCourseFilter', value)
      },
    },
    selectedStreams: {
      get() {
        return this.selectedStreamsVuex
      },
      set(value) {
        this.$store.commit('homeworkReviewStore/setStreamsFilter', value)
      },
    },
    selectedLessons: {
      get() {
        return this.selectedLessonsVuex
      },
      set(value) {
        this.$store.commit('homeworkReviewStore/setLessonsFilter', value)
      },
    },
    selectedMentors: {
      get() {
        return this.selectedMentorsVuex
      },
      set(value) {
        this.$store.commit('homeworkReviewStore/setMentorsFilter', value)
      },
    },
    filteredStreams() {
      if (this.selectedCourse)
        return this.streams
          .filter(s => s.courseId === this.selectedCourse.id)
          .sort((s1, s2) => {
            const split1 = s1.dateStart.split('.')
            const date1 = new Date(
              [split1[1], split1[0], split1[2]].join('.'),
            ).getTime()
            const split2 = s2.dateStart.split('.')
            const date2 = new Date(
              [split2[1], split2[0], split2[2]].join('.'),
            ).getTime()

            return date2 - date1
          })
      return []
    },
    mentorsPermission() {
      return this.user.group.permissions['admin.homework.mentors.view']
    },
  },
  methods: {
    streamNameFromId(id) {
      return this.streams.find(stream => stream.id === id)?.title
    },
    async onStreamsChanged() {
      await this.$store.dispatch('homeworkReviewStore/fetchLessonsAndMentors')
      await this.fetchStudents()
      this.updateRouteQuery()
    },
    async onLessonsChanged() {
      await this.fetchStudents()
      this.updateRouteQuery()
    },
    async onMentorsChanged() {
      await this.fetchStudents()
      this.updateRouteQuery()
    },
    async fetchStudents() {
      await this.$store.dispatch('homeworkReviewStore/fetchStudents')
      if (
        this.student &&
        this.students.some(student => student.id == this.student.id)
      ) {
        await this.$store.dispatch(
          'homeworkReviewStore/fetchDetailedStudentData',
          this.student.id,
        )
        if (
          this.dialogId &&
          !this.detailedLessons.some(lesson => lesson.id === this.dialogId)
        )
          this.$store.commit('homeworkReviewStore/hideDialog')
      } else {
        this.$store.commit('homeworkReviewStore/clearStudent')
      }
    },
    updateRouteQuery() {
      this.$store.dispatch('homeworkReviewStore/updateRouteQuery')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.panel {
  border-bottom: 1px solid $input-border-color;
  padding: 10px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.03);

  .table-button {
    height: 37px;
  }

  .select {
    .course-option {
      padding: 5px;
      overflow: hidden;

      .title {
        overflow: hidden;
      }

      .image-wrapper {
        width: 35px;
        height: 35px;
        min-width: 35px;
        border-bottom: 1px solid $input-border-color;
        border-radius: 5px;

        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }

      &.selected {
        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .image-wrapper {
          width: 27px;
          height: 27px;
          min-width: 27px;
        }
      }
    }

    .option {
      padding: 5px;
      overflow: hidden;

      .title {
        overflow: hidden;
      }
      .bottom-line {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $text-muted;
      }
    }
  }
}

.dark-layout {
  .panel {
    border-color: $theme-dark-border-color;
  }
}
</style>
