<template>
  <div class="students-panel" :class="{ collapsed }">
    <div class="wrapper d-flex flex-column">
      <div class="top" :class="{ scrolled }">
        <div class="search-wrapper d-flex">
          <b-input-group class="search-bar input-group-merge round">
            <b-input-group-prepend is-text>
              <feather-icon class="text-muted" icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              @input="searchInputHandler"
              placeholder="Поиск по студентам"
            />
          </b-input-group>
          <b-button
            variant="flat-primary"
            class="collapse-button p-50"
            @click="$emit('collapse')"
          >
            <feather-icon
              size="20"
              icon="ChevronLeftIcon"
              class="collapse-icon"
              :class="{ 'rotate-180': collapsed }"
            />
          </b-button>
        </div>
        <div class="sort-panel d-flex align-items-center">
          <div class="sort flex-grow-1" @click="sortClick">
            <span>сначала {{ newFirst ? 'новые' : 'старые' }}</span>
            <feather-icon
              :class="{ 'rotate-180': !newFirst }"
              class="sort-icon"
              icon="ArrowUpIcon"
            />
          </div>
          <div class="filter-new d-flex align-items-center">
            <label for="new-filter">только новые</label>
            <b-form-checkbox
              id="new-filter"
              v-model="newOnly"
              size="lg"
              class="switch-new p-0"
              switch
            />
          </div>
        </div>
      </div>

      <vue-perfect-scrollbar class="overflow-hidden h-100" ref="scrollarea">
        <BOverlay no-wrap :show="pendingStudents" />
        <transition-group name="sort">
          <student-view
            v-for="student in filteredStudents"
            :key="student.id"
            :student="student"
            :class="{
              selected: selectedStudent
                ? student.id === selectedStudent.id
                : false,
            }"
            v-height-fade.appear
            @click.native="selectStudent(student.id)"
          ></student-view>
        </transition-group>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import StudentView from '@/components/homeworkReview/StudentView.vue'
import { heightFade } from '@core/directives/animations'
import { BOverlay } from '@/components/bootstrap'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  components: {
    StudentView,
    VuePerfectScrollbar,
    BOverlay,
  },
  directives: {
    'height-fade': heightFade,
  },
  props: {
    collapsed: { type: Boolean, default: false },
  },
  data: () => ({
    search: '',
    scrolled: false,
    newFirst: false,
    newOnly: false,
  }),
  watch: {
    newFirst() {
      saveToLS('sortNewFirst', this.newFirst)
    },
    newOnly() {
      saveToLS('filterNewOnly', this.newOnly)
    },
  },
  computed: {
    ...mapGetters({
      students: 'homeworkReviewStore/getStudents',
      selectedStudent: 'homeworkReviewStore/getStudent',
      pendingStudents: 'homeworkReviewStore/getPendingStudents',
    }),
    filteredStudents() {
      let students = this.students.filter(
        student =>
          student.name.toLowerCase().includes(this.search.toLowerCase()) ||
          student.email.toLowerCase().includes(this.search.toLowerCase()),
      )

      if (this.newFirst) {
        students = students.sort((s1, s2) => s2.timeInt - s1.timeInt)
      } else {
        students = students.sort((s1, s2) => s1.timeInt - s2.timeInt)
      }

      if (this.newOnly) {
        students = students.filter(student => student.messagesCount > 0)
      }

      return students
    },
  },
  mounted() {
    this.$refs.scrollarea.$el.addEventListener('scroll', this.scrollHandler)
    const sortNewFirst = loadFromLS('sortNewFirst')
    const filterNewOnly = loadFromLS('filterNewOnly')

    if (sortNewFirst !== undefined) {
      this.newFirst = sortNewFirst
    }

    if (filterNewOnly !== undefined) {
      this.newOnly = filterNewOnly
    }
  },
  beforeDestroy() {
    this.$refs.scrollarea.$el.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    searchInputHandler() {
      this.$refs.scrollarea.$el.scrollTo({ top: 0, behavior: 'smooth' })
    },
    scrollHandler() {
      this.scrolled = this.$refs.scrollarea.$el.scrollTop !== 0
    },
    async selectStudent(id) {
      this.selectedStudentId = id
      await this.$store.dispatch(
        'homeworkReviewStore/fetchDetailedStudentData',
        id,
      )
      await this.$store.dispatch('homeworkReviewStore/updateRouteQuery')
      this.$store.commit('homeworkReviewStore/hideDialog')
    },
    sortClick() {
      this.newFirst = !this.newFirst
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

.sort-enter,
.sort-leave {
  opacity: 0;
}

.sort-move,
.sort-enter-active,
.sort-leave-active {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.students-panel {
  border-right: 1px solid $input-border-color;
  width: 350px;
  height: 100%;
  overflow: hidden;
  transition: 0.5s ease-in-out;

  &.collapsed {
    width: 80px;
    background: #f2f0f7;

    .search-bar {
      width: 0;
      opacity: 0;
      visibility: hidden;
    }

    .collapse-button {
      width: 60px !important;
      height: 60px !important;

      .collapse-icon {
        transform: scale(2) rotate(180deg);
      }
    }

    .sort-panel {
      opacity: 0;
      visibility: hidden;
    }
  }

  .wrapper {
    overflow: hidden;
    width: 350px;
    height: 100%;

    .top {
      height: 72px;
      min-height: 72px;

      &.scrolled {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
      }

      .search-wrapper {
        width: 100%;
        padding: 5px 10px;
        transition: 0.3s;
        z-index: 1;

        .collapse-button {
          width: 36px;
          height: 37px;

          .collapse-icon {
            transition: 0.5s ease-in-out;
          }
        }

        .collapse-button,
        .search-bar {
          transition: 0.5s ease-in-out;
        }

        .search-bar {
          height: 37px;
        }
      }

      .sort-panel {
        padding: 0 5px 0 20px;
        transition: 0.5s ease-in-out;
        overflow: hidden;

        .sort {
          cursor: pointer;
          user-select: none;

          .sort-icon {
            transition: 0.2s;
          }
        }

        .filter-new {
          label {
            font-size: 1rem;
            margin: 0;
            user-select: none;
          }

          .switch-new {
            transform: translate(45px, -6px);
          }
        }
      }
    }
  }
}

.dark-layout {
  .students-panel {
    border-color: $theme-dark-border-color;

    &.collapsed {
      background: $theme-dark-input-bg;
    }
  }
}
</style>
